@import '../../scss/variables.scss';

.headingBox {
    font-size: 2rem;
    padding-top: 5%;
    padding-left: 96px;
    width: 1296px;
}
.headingBox .pageTitle{
	display: inline-block;
	vertical-align: top;
	width: 400px;
	color: $color-iris;
}
.headingBox .toolbarContainer{
	display: inline-block;
	vertical-align: top;
}
.toolbarButton{
	height: 80px;
}
.toolbarButton span{
	display: block;
}

.filterButton{
	height: 80px;
	background-color: transparent;
}
.filterButton span{
	display: block;
}


.filterButton :active{
	color: $color-blue;
	background-color: transparent;
}
