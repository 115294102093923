@import '../../scss//variables.scss';

.loginBox {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 500px;
    height: 300px;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    background-color: #fff;
    margin: 0;
    transform: translate(-50%, -50%);
}
.loginTitle {
    font-size: 21px;
    font-weight: bold;
    color: $color-carrot;
    padding-top: 50px;
    margin-left: 60px;
}
.formContainer {
    margin-top: 20px;
    margin-left: 60px;
    width: 385px;
}
.buttonContainer {
    width: 100%;
    height: 80px;
}
.buttonContainer button {
    width: 385px;
    margin: 30px 0 0 60px;
    padding: 0 0 0 0;
    background-color: $color-carrot;
    border-radius: 4px;
}
.progress {
    position: relative;
    left: 50%;
    top: 25%
}
.background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #f1f1f1;
    background-color: #f9f9f9;
    z-index: 1;
}

@media only screen and (max-width: 600px) {
    .loginBox {
       width: 300px;
    }
    .formContainer {
        width: 185px;
    }
    .loginTitle {
    margin-left: 40px;
    }
    .buttonContainer button {
        width: 185px;
    }
}