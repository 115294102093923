@import "../../scss/variables.scss";

.welcomeBox {
  margin-left: 96px;
  margin-top: 19px;
  padding-bottom: 50px;
}

// todo: media query
.screenManager {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
//   padding-left: 96px;
  width: 60vw;
  justify-content: space-evenly;
}

// /* ----------- 4k Screens ----------- */
@media screen 
  and (min-device-width: 2000px) 
  { 
    .screenManager {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    //   padding-left: 96px;
      width: 90vw;
      justify-content: space-evenly;
    }
}

/* ----------- 1920 x 1080p Screens ----------- */
@media screen 
  and (max-device-width: 1920px) 
  and (min-device-width: 1601px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
    .screenManager {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-left: 96px;
      width: 75vw;
      justify-content: space-evenly;
    }
}

/* ----------- Retina Screens ----------- */
@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) { 
    .screenManager {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-left: 96px;
      width: 70vw;
      justify-content: space-evenly;
    }
}


.screenManagerContainer {
  display: flex;
  padding-left: 40px;
  padding-bottom: 125px;
}

.screenManagerTable {
  padding-left: 96px;
}

.headingBox {
  font-size: 36px;
  color: $color-blue;
  padding-top: 139px;
  padding-left: 96px;
  width: 1296px;
  display: flex;
}

.searchBox {
  padding-left: 96px;
  padding-bottom: 2%;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: bold;
  height: 38px;
  width: 410px;
  display: inline-block;
}

.iconBox {
  padding-left: 311px;
}

.filter {
  width: 328px;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 1;
}

.menu {
  // position: -webkit-sticky;
  // position: sticky;
  // position: fixed;
  position: fixed;
  bottom: 0;
  // left: 25vh;
  left: 0%;
  right: 0%;
  margin-left: 50px;
  // margin: 0 auto 0 auto;
  // align-self: flex-start;
  align-items: center;
}
