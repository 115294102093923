@import "../../scss/variables.scss";

.menu {
  width: 896px;
  height: 128px;
  background-color: $color-white;
  border-radius: 8px;
  color: $color-blue;
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  border-radius: 8px;
  display: flex;
  z-index: -10;
  position: relative;
  margin: 0 auto;
  bottom: 25px;
  box-shadow: 0px 0px 32px #00000029;
}

.selectedNumber {
    display: inline-block;
    width: 128px;
    height: 128px;
    background-color: $color-blue;
    border-radius: 8px 0px 0px 8px;
    color: $color-white;
    font-size: 48px;
    text-align: center;
    justify-content: center;
}

.numPosition {
  padding-top: 26px;
}

.iconContainer {
  border-right: 2px solid $color-concrete;
  padding: 10px;
  text-align: center;
  flex: 1;
  margin-top: 10px;
  margin-bottom: 10px;
}

.clearContainer {
  flex: 1;
  top: 30%;
  position: relative;
  left: 2%;
}

.iconText {
  padding-top: 10px;
  text-transform: uppercase;
}