.App {
  display:flex;
  background-color: #fff;
  font-family: 'Silka' !important;
}

.body {
  background-color: #fafafa;
  width: 85%;
  width: 100%;
  overflow: hidden;
}

.Navigation {
  position: -webkit-sticky;
  position: sticky;
}

@font-face {
  font-family: "Silka";
  src: local("Silka"),
    url("./fonts/Silka\ Regular.otf") format("truetype");
}

@font-face {
  font-family: "Silka";
  src: local("Silka"),
  url("./fonts/Silka\ Bold.otf") format("truetype");
  font-weight: bold;
}