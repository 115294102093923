@import '../../scss/variables.scss'; 

.railpoints {
    height: 256px;
    min-width: 144px;
    border-radius: 8px;
    background-color: white;
    border: 4px solid $color-concrete;
    text-align: center;
    margin-bottom: 10px;
  }

  .railpointsWithImage {
    height: 256px;
    min-width: 144px;
    border-radius: 8px;
    background-color: transparent;
    border: 4px solid $color-concrete;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .railpointsSelected {
    height: 256px;
    min-width: 144px;
    background-color: #465AF6;
    border: 4px solid #465AF6;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 10px;
}

.name {
    margin-top: 10px ;
    font-size: 1rem;;
    max-width: 160px;
    border-radius: 16px;
    text-align: center;
    padding: 5px;
    cursor: default;
    margin: 0 auto;
}

.name:hover {
  background-color: #E8E8E8;  
}

.nameSelected {
  margin-top: 10px ;
  font-size: 1rem;;
  max-width: 160px;
  border-radius: 16px;
  text-align: center;
  background-color: #465AF6;
  color: white;
  padding: 5px;
  cursor: default;
  margin: 0 auto;
}

.railpointContainer {
  height: 300px;
  flex-direction: column;
  padding-bottom: 43px;
  margin-top: 20px;
  margin: 1%;
  animation: fadein 1s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s; 
}
@keyframes fadein {
from {
    opacity:0;
}
to {
    opacity:1;
}
}
@-moz-keyframes fadein { /* Firefox */
from {
    opacity:0;
}
to {
    opacity:1;
}
}
@-webkit-keyframes fadein { /* Safari and Chrome */
from {
    opacity:0;
}
to {
    opacity:1;
}
}
@-o-keyframes fadein { /* Opera */
from {
    opacity:0;
}
to {
    opacity: 1;
}
}

.railpointContainer {
    height: 300px;
    flex-direction: column;
    padding-bottom: 43px;
    margin-top: 20px;
    margin: 1%;
    animation: fadein 1s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; 
}
@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
      opacity:0;
  }
  to {
      opacity: 1;
  }
}

.iconPosition {
    margin-left: 8px;
    margin-top: 8px;
    position: absolute;
}

.screenshot {
    width: inherit;
    height: inherit;
    animation: fadein 1s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; 
    cursor: zoom-in;
    border-radius: 8px;
}
@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
      opacity:0;
  }
  to {
      opacity: 1;
  }
}

.noScreenShot {
    width: 120px;
    margin-top: 50%;
    fill: red !important;
    cursor: zoom-in
}


.ldsRing {
    display: inline-block;
    position: relative;
    padding-top: 75px;
    padding-left: 40px;
    width: 80px;
    height: 80px;
  }
  
  .ldsRing div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #cef;
    border-radius: 50%;
    animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $color-iris transparent transparent transparent;
  }
  .ldsRing div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .ldsRing div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .ldsRing div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes ldsRing {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .fullScreenshot {
    position: fixed;
    top: 5%;
    left: 25%; 
    height: 90%;
    overflow: scroll;
  }

  .offline {
    box-shadow: 0px 0px 20px 2px #FF3A20;
    border: 1px;
    border-radius: 8px;
    height: auto;
    width: auto;
  }

  .potentiallyOffline {
    box-shadow: 0px 0px 20px 1px #fe9000;
    border-radius: 8px;
  }


  .online{
    box-shadow: 0px 0px 20px 1px green;
    border-radius: 8px;
  }
  