@import '../../scss//variables.scss';

.online {
    height: 5px;
    width: 5px;
    background-color: $color-lime;
    border-radius: 50%;
    display: inline-block;
  }
  
  .offline {
    height: 5px;
    width: 5px;
    background-color: $color-rocket;
    border-radius: 50%;
    display: inline-block;
  }
