@import '../../scss/variables.scss';

.container {
    margin-left: 176px;
    margin-top: 5%;
}

.screenName {
    font-size: 36px;
    color: $color-iris;
}

.screenDetail {
    font-size: 21px;
    margin-top: 16px;
}

.iconContainer{
    padding-top: 25px;
}

.detailContainer {
    margin-top: 94px;
    display: flex;
}

.detailTitle {
    font-size: 14px;
    font-weight: bold;
    color: $color-iris;
}

.detailText {
    width: 241px;
    font-size: 1rem;;
    color: $color-black;
    font-weight: normal;
}

.noteContainer {
    padding-top: 53px;
}

.noteText { 
    font-size: 1rem;;
    max-width: 543px;
    border-bottom: 1px solid $color-iris;
    padding-bottom: 10px;
}

.buttonPosition {
    margin-top: 60px;
    margin-bottom: 48px;
}

.option {
    color: $color-iris;
    font-weight: bold;
    text-transform: uppercase;
    width: 160px;
}

.data {
    width: 200px;
}

.optionContainer {
    margin-top: 9px;
    display: flex;
    flex-direction: row;
}

.bottomContainer {
    width: 800px;
    display: flex;
    flex-wrap: wrap;
}

.screenShot {
    background-color: white;
    width: 144px;
    height: 256px;
    float: right;
    margin-top: -80px;
    margin-right: 700px;
}

.searchIcon {
    margin-top: 96px;
    margin-left: 40px;
}

.refreshIcon {
    margin-left:110px;
    margin-top: 90px;

}

.smallThumbnail {
    height: inherit;
}
