@import '../../scss/variables.scss';

.card {
    height: 304px;
    width: 304px;
    background-color:$color-white;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-right: 16px;
    margin: 16px;
}

.cardTitle {
    margin-left: 32px;
    margin-top: 32px;
    font-size: 25px;
    font-weight: bold;
    color: $color-blue;
}