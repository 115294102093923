@import '../../scss//variables.scss';

.iconContainer {
    padding-right: 16px;
}

.welcomeInfo {
    padding-top: 2px;
    font-size: 1.25rem;
    display: flex;
}

@media screen 
and (min-device-width: 1200px) 
and (max-device-width: 1600px) 
and (-webkit-min-device-pixel-ratio: 2)
and (min-resolution: 192dpi) { 
    .welcomeInfo {
        font-size: 1rem;
        display: flex;
    }
}

.details {
    color: $color-blue;
    text-decoration: underline;
    cursor: default;
}