@import '../../scss/variables.scss';

.container {
    position: absolute;
    border-radius: 8px;
    background-color: white;
    top: 20%;
    left: 20%;
    width: 40vw;
    height: 70vh;
    height: auto;
    padding: 1%;
    display: flex;
}

.screenshot {
    flex: 1;
    border-radius: 8px;
    padding: 5px;
    min-height: 480px;
    min-width: 270px;
}

.screenDetails {
    flex: 1;
}

.screenTitle {
    font-size: 1.563rem;
    color: $color-iris;
    margin: 16px;
}

.description {
    margin: 16px;
}

.detailContainer {
    margin: 16px;
}

.iconContainer {
    margin-top: 16px;;
}

    .iconButton {
        height: 80px;
        color: $color-iris;
        margin: 16px;
}
.iconButton span{
	display: block;
    font-weight: bold;
    color: $color-iris;
}


.detailContainer {
    margin-top: 10%;
}

.detailTitle {
    font-weight: bold;
    margin-top: 5px;
}

.detailText {
    font-weight: 400;
    padding-top: 2px;
}

.noteContainer {
    margin: 16px;
}
