@import '../../scss//variables.scss';

.iconContainer {
    padding-right: 16px;
}

.container {
    padding-top: 16px;
    font-size: 20px;
    display: flex;
    margin-left: 96px;
}

.details {
    color: $color-blue;
    text-decoration: underline;
    cursor: default;
}