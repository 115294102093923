@import "./app/scss/variables.scss";

body {
  margin: 0;
  font-family: "Silka";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  color: $color-blue;
  font-size: 20px;
  font-weight: bold;
}

h2 {
  color: $color-blue;
  font-size: 24px;
  font-weight: bold;
}

h3 {
  color: $color-blue;
  font-size: 28px;
  font-weight: bold;
}

h4 {
  color: $color-blue;
  font-size: 2rem;;
  font-weight: bold;
}

h5 {
  color: $color-blue;
  font-size: 36px;
  font-weight: bold;
}

button {
  background-color: $color-blue;
  color: $color-white;
  height: 40px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
}

button:focus {
  color: $color-blue; opacity:0.8;  
  color: white;
}

.d1 {
  color: $color-blue;
  font-size: 2rem;;
}

.overline {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

@font-face {
  font-family: "Silka";
  src: local("Silka"),
    url("./fonts/Silka\ Regular.otf") format("truetype");
}

@font-face {
  font-family: "Silka";
  src: local("Silka"),
    url("./fonts/Silka\ Bold.otf") format("truetype");
  font-weight: bold;
}