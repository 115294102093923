@import '../../app/scss/variables.scss';


.navigationBar {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    width: 50px;
    width: 48px;
    height: 100vh;
    padding-top: 1px;
    text-align: left;
    color: $color-white;
    background-color: #1C1E3B;
    overflow: hidden;
}
.toggleWidth {
    width: 250px;
}
.railpoint {
    font-size: 21px;
    font-weight: bold;
    color: $color-blue;
    padding-top: 32px;
    padding-left: 32px;
    padding-bottom: 71px;
    text-decoration: none;
    // transform: rotate(-90deg);
}
.railpoint:visited {
    text-decoration: none;
}
.splitter {
    color: #828EFF;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    padding-left: 32px;
    padding-top: 49px;
    padding-bottom: 17px;
}
.logoutContainer {
    position: absolute;
    bottom: 0px;
}
.logoWrapper {
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 50px;
}
.railpointLogo {
    height: 100px;
    background-image: url("../../images/railpoint_logo.svg");
    background-position: center;
    background-repeat: no-repeat;
}

.navButton {
    position: relative;
    height: 38px;
    width: 42px;
    margin: 4px 3px;
    background-color: rgba(255,255,255,0.20);
    background-color: transparent;
    border-radius: 2px;
}

.navButton:hover {
    background-color: rgba(255, 255, 255, 0.10);
}

.toggleButton {
    width: 242px;
}

.navButtonInner {
    font-size: 22px;
    font-weight: 400;
    color: #aaa;
    position: absolute;
    top: 50%;
    height: 26px;
    width: 40px;
    margin: 0 auto;
    text-align: center;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);    
}

// .navIcon {
//     color: #ffffff;
// }

.navText {
    font-weight: 700;
    position: absolute;
    width: 150px;
    margin: 10px 0 0 40px;
    color: #ffffff;
}

.navDividerText {
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    margin: 13px 0;
}



.navButtonExpand {
    height: 42px;
    width: 42px;
    height: 42px;
    width: 50px;
    background-color: rgba(255,255,255,0.20);
    border-top: 1px solid rgba(255,255,255,0.25);
    position: absolute;
    bottom: 0px;
}

.navButtonExpand:hover {
    background-color: rgba(255,255,255,0.50);
}
.navExpandButtonInner {
    font-size: 22px;
    font-weight: 400;
    position: absolute;
    top: 50%;
    height: 26px;
    width: 50px;
    margin: 0 auto;
    text-align: center;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.expand {
    width: 250px;
}
