$color-platinum: #F2F2F2;
$color-white: #FFFFFF;
$color-black: #000000;
$color-blue: #4556FF;
$color-grey: #2B2B38;

// Primary Colours
$color-iris: #4455FF;
$color-raisin: #2B2B38;
$color-concrete: #F2F2F2;
$color-platinum: #E8E8E8;
$color-nickel: #7D7D77;

// Secondary Colours
$color-rocket: #FF1155;
$color-carrot: #FF7700;
$color-lime: #66DD55;