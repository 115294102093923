@import './../../scss/variables.scss';

.filter {
    max-width: 368px;
    height: 100%;
    min-height: 100vh;
    border-left: 1px solid #2B2B37;
    background-color: $color-platinum;
    font-weight: bold;
    right: 10px;
    top: 0px;
}

.filterTitle {
    color: $color-blue;
    font-weight: bold;
    font-size: 21px;
    padding-left: 60px;
    padding-top: 128px;
}

.formContainer {
    margin-top: 20px;
    padding-left: 62px;
    font-size: 14px;
    letter-spacing: 0.28px;
    text-transform: uppercase;
}

.operatorContainer {
    margin-top: 20px;
    padding-left: 62px;
    font-size: 14px;
    letter-spacing: 0.28px;
    text-transform: uppercase;
    max-height: 200px;
    overflow: scroll;
}

p {
    color: $color-blue;
    font-weight: bold;
}

.buttonContainer {
    margin-top: 16px;
    margin-left: 62px;
}

.filterName {
    margin-left: 62px;
}
