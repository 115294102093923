.feedContainer {
     margin-left: 32px;
     margin-top: 3px;
}

.redIcon {
     color: #FF1053;
}

.greenIcon {
     color: #66DD55;
}