@import '../../scss/variables.scss';

.reportContainer{
    height: 400px;
    width: 800px;
    border-radius: 8px;
    background-color: white;
}

.reportTitle {
    font-size: 21px;
    font-weight: bold;
    color: $color-iris;
    padding-top: 32px;
    margin-left: 32px;
}

.formContainer {
    margin-top: 70px;
    margin-left: 30px;
    width: 750px;
}

.buttonContainer {
    margin-top: 1.5%;
    margin-left: 80%;
}